export const dataSliders = [
   {
      id: 383,
      attributes: {
         info: 'Page Home',
         title: 'Your World, Seamlessly Connected',
         description:
            '<p>Backed by a reliable network, delivering fast and stable connections for your home or business.</p><p>&nbsp;</p><p><strong>Get up to 20% OFF and a FREE Router!</strong></p>',
         createdAt: '2023-05-10T06:49:56.011Z',
         updatedAt: '2024-11-13T03:28:38.561Z',
         publishedAt: '2023-05-10T06:49:57.652Z',
         locale: 'en',
         link: '/coverage-area',
         isLinkTargetBlank: false,
         linkName: 'Get Yours Now',
         isVideo: false,
         thumbnail: {
            data: {
               id: 3564,
               attributes: {
                  name: 'head-page-home-2024-10-24.jpg',
                  alternativeText: null,
                  caption: null,
                  width: 3252,
                  height: 2013,
                  formats: {
                     large: {
                        ext: '.jpg',
                        url: '/uploads/large_head_page_home_2024_10_24_a5f3944c2f.jpg',
                        hash: 'large_head_page_home_2024_10_24_a5f3944c2f',
                        mime: 'image/jpeg',
                        name: 'large_head-page-home-2024-10-24.jpg',
                        path: null,
                        size: 51.4,
                        width: 1000,
                        height: 619,
                     },
                     small: {
                        ext: '.jpg',
                        url: '/uploads/small_head_page_home_2024_10_24_a5f3944c2f.jpg',
                        hash: 'small_head_page_home_2024_10_24_a5f3944c2f',
                        mime: 'image/jpeg',
                        name: 'small_head-page-home-2024-10-24.jpg',
                        path: null,
                        size: 18.71,
                        width: 500,
                        height: 309,
                     },
                     medium: {
                        ext: '.jpg',
                        url: '/uploads/medium_head_page_home_2024_10_24_a5f3944c2f.jpg',
                        hash: 'medium_head_page_home_2024_10_24_a5f3944c2f',
                        mime: 'image/jpeg',
                        name: 'medium_head-page-home-2024-10-24.jpg',
                        path: null,
                        size: 33.29,
                        width: 750,
                        height: 464,
                     },
                     thumbnail: {
                        ext: '.jpg',
                        url: '/uploads/thumbnail_head_page_home_2024_10_24_a5f3944c2f.jpg',
                        hash: 'thumbnail_head_page_home_2024_10_24_a5f3944c2f',
                        mime: 'image/jpeg',
                        name: 'thumbnail_head-page-home-2024-10-24.jpg',
                        path: null,
                        size: 6.48,
                        width: 245,
                        height: 151,
                     },
                  },
                  hash: 'head_page_home_2024_10_24_a5f3944c2f',
                  ext: '.jpg',
                  mime: 'image/jpeg',
                  size: 290.97,
                  url: '/uploads/head_page_home_2024_10_24_a5f3944c2f.jpg',
                  previewUrl: null,
                  provider: 'local',
                  provider_metadata: null,
                  createdAt: '2024-10-24T09:10:20.410Z',
                  updatedAt: '2024-10-24T09:10:20.410Z',
               },
            },
         },
         mobile_thumbnail: {
            data: {
               id: 3563,
               attributes: {
                  name: 'mobile-home-page-page-2024-10-24.jpg',
                  alternativeText: null,
                  caption: null,
                  width: 1080,
                  height: 1920,
                  formats: {
                     large: {
                        ext: '.jpg',
                        url: '/uploads/large_mobile_home_page_page_2024_10_24_5b82a008af.jpg',
                        hash: 'large_mobile_home_page_page_2024_10_24_5b82a008af',
                        mime: 'image/jpeg',
                        name: 'large_mobile-home-page-page-2024-10-24.jpg',
                        path: null,
                        size: 51.45,
                        width: 563,
                        height: 1000,
                     },
                     small: {
                        ext: '.jpg',
                        url: '/uploads/small_mobile_home_page_page_2024_10_24_5b82a008af.jpg',
                        hash: 'small_mobile_home_page_page_2024_10_24_5b82a008af',
                        mime: 'image/jpeg',
                        name: 'small_mobile-home-page-page-2024-10-24.jpg',
                        path: null,
                        size: 17.66,
                        width: 281,
                        height: 500,
                     },
                     medium: {
                        ext: '.jpg',
                        url: '/uploads/medium_mobile_home_page_page_2024_10_24_5b82a008af.jpg',
                        hash: 'medium_mobile_home_page_page_2024_10_24_5b82a008af',
                        mime: 'image/jpeg',
                        name: 'medium_mobile-home-page-page-2024-10-24.jpg',
                        path: null,
                        size: 32.14,
                        width: 422,
                        height: 750,
                     },
                     thumbnail: {
                        ext: '.jpg',
                        url: '/uploads/thumbnail_mobile_home_page_page_2024_10_24_5b82a008af.jpg',
                        hash: 'thumbnail_mobile_home_page_page_2024_10_24_5b82a008af',
                        mime: 'image/jpeg',
                        name: 'thumbnail_mobile-home-page-page-2024-10-24.jpg',
                        path: null,
                        size: 3.23,
                        width: 88,
                        height: 156,
                     },
                  },
                  hash: 'mobile_home_page_page_2024_10_24_5b82a008af',
                  ext: '.jpg',
                  mime: 'image/jpeg',
                  size: 180.4,
                  url: '/uploads/mobile_home_page_page_2024_10_24_5b82a008af.jpg',
                  previewUrl: null,
                  provider: 'local',
                  provider_metadata: null,
                  createdAt: '2024-10-24T09:10:20.206Z',
                  updatedAt: '2024-10-24T09:10:20.206Z',
               },
            },
         },
         thumbnail_video: {
            data: {
               id: 3380,
               attributes: {
                  name: 'head-page-video.mp4',
                  alternativeText: 'globalxtreme-head-page-video',
                  caption: null,
                  width: null,
                  height: null,
                  formats: null,
                  hash: 'head_page_video_f534176b85',
                  ext: '.mp4',
                  mime: 'video/mp4',
                  size: 13135.82,
                  url: '/uploads/head_page_video_f534176b85.mp4',
                  previewUrl: null,
                  provider: 'local',
                  provider_metadata: null,
                  createdAt: '2023-12-15T02:45:57.232Z',
                  updatedAt: '2024-07-08T03:24:25.890Z',
               },
            },
         },
         mobile_thumbnail_video: {
            data: {
               id: 3536,
               attributes: {
                  name: 'mobile-head-page-video.mp4',
                  alternativeText: null,
                  caption: null,
                  width: null,
                  height: null,
                  formats: null,
                  hash: 'mobile_head_page_video_41b8a8dc0a',
                  ext: '.mp4',
                  mime: 'video/mp4',
                  size: 13143.6,
                  url: '/uploads/mobile_head_page_video_41b8a8dc0a.mp4',
                  previewUrl: null,
                  provider: 'local',
                  provider_metadata: null,
                  createdAt: '2024-01-13T11:02:58.516Z',
                  updatedAt: '2024-07-08T03:42:58.944Z',
               },
            },
         },
         localizations: {
            data: [
               {
                  id: 388,
                  attributes: {
                     info: 'Halaman Utama',
                     title: 'Selalu Terhubung, Tanpa Batas',
                     description:
                        '<p>Dapatkan koneksi cepat dan stabil dengan jaringan yang handal untuk rumah atau bisnis Anda.<br><br><strong>Nikmati diskon hingga 20% dan router GRATIS!</strong></p>',
                     createdAt: '2023-05-20T07:29:42.640Z',
                     updatedAt: '2024-10-25T03:18:46.790Z',
                     publishedAt: '2023-05-20T07:29:43.744Z',
                     locale: 'id',
                     link: 'https://globalxtreme.net/id/coverage-area',
                     isLinkTargetBlank: false,
                     linkName: 'Dapatkan Sekarang',
                     isVideo: false,
                  },
               },
            ],
         },
      },
   },
   {
      id: 384,
      attributes: {
         info: 'Page Product',
         title: 'Fiber Optic Broadband',
         description:
            '<p>Maximize your productivity with our lightning-fast Internet<br>and enjoy the best-in-class service.</p>',
         createdAt: '2023-05-10T06:50:52.324Z',
         updatedAt: '2024-09-09T04:20:47.015Z',
         publishedAt: '2023-05-10T06:50:53.253Z',
         locale: 'en',
         link: '/coverage-area',
         isLinkTargetBlank: false,
         linkName: 'Check Coverage',
         isVideo: false,
         thumbnail: {
            data: {
               id: 3421,
               attributes: {
                  name: 'head-page-product.jpg',
                  alternativeText: 'globalxtreme-head-page-product',
                  caption: null,
                  width: 2376,
                  height: 1584,
                  formats: {
                     large: {
                        ext: '.jpg',
                        url: '/uploads/large_head_page_product_ba595dfc08.jpg',
                        hash: 'large_head_page_product_ba595dfc08',
                        mime: 'image/jpeg',
                        name: 'large_head-page-product.jpg',
                        path: null,
                        size: 77.91,
                        width: 1000,
                        height: 667,
                     },
                     small: {
                        ext: '.jpg',
                        url: '/uploads/small_head_page_product_ba595dfc08.jpg',
                        hash: 'small_head_page_product_ba595dfc08',
                        mime: 'image/jpeg',
                        name: 'small_head-page-product.jpg',
                        path: null,
                        size: 25.11,
                        width: 500,
                        height: 333,
                     },
                     medium: {
                        ext: '.jpg',
                        url: '/uploads/medium_head_page_product_ba595dfc08.jpg',
                        hash: 'medium_head_page_product_ba595dfc08',
                        mime: 'image/jpeg',
                        name: 'medium_head-page-product.jpg',
                        path: null,
                        size: 48.16,
                        width: 750,
                        height: 500,
                     },
                     thumbnail: {
                        ext: '.jpg',
                        url: '/uploads/thumbnail_head_page_product_ba595dfc08.jpg',
                        hash: 'thumbnail_head_page_product_ba595dfc08',
                        mime: 'image/jpeg',
                        name: 'thumbnail_head-page-product.jpg',
                        path: null,
                        size: 7.46,
                        width: 234,
                        height: 156,
                     },
                  },
                  hash: 'head_page_product_ba595dfc08',
                  ext: '.jpg',
                  mime: 'image/jpeg',
                  size: 200.02,
                  url: '/uploads/head_page_product_ba595dfc08.jpg',
                  previewUrl: null,
                  provider: 'local',
                  provider_metadata: null,
                  createdAt: '2023-12-20T02:23:54.637Z',
                  updatedAt: '2024-09-09T04:20:27.429Z',
               },
            },
         },
         mobile_thumbnail: {
            data: {
               id: 3422,
               attributes: {
                  name: 'mobile-head-page-product.jpg',
                  alternativeText: 'globalxtreme-mobile-head-page-product',
                  caption: null,
                  width: 1500,
                  height: 2463,
                  formats: {
                     large: {
                        ext: '.jpg',
                        url: '/uploads/large_mobile_head_page_product_28bfe4a41c.jpg',
                        hash: 'large_mobile_head_page_product_28bfe4a41c',
                        mime: 'image/jpeg',
                        name: 'large_mobile-head-page-product.jpg',
                        path: null,
                        size: 57.88,
                        width: 609,
                        height: 1000,
                     },
                     small: {
                        ext: '.jpg',
                        url: '/uploads/small_mobile_head_page_product_28bfe4a41c.jpg',
                        hash: 'small_mobile_head_page_product_28bfe4a41c',
                        mime: 'image/jpeg',
                        name: 'small_mobile-head-page-product.jpg',
                        path: null,
                        size: 19.05,
                        width: 305,
                        height: 500,
                     },
                     medium: {
                        ext: '.jpg',
                        url: '/uploads/medium_mobile_head_page_product_28bfe4a41c.jpg',
                        hash: 'medium_mobile_head_page_product_28bfe4a41c',
                        mime: 'image/jpeg',
                        name: 'medium_mobile-head-page-product.jpg',
                        path: null,
                        size: 36.32,
                        width: 457,
                        height: 750,
                     },
                     thumbnail: {
                        ext: '.jpg',
                        url: '/uploads/thumbnail_mobile_head_page_product_28bfe4a41c.jpg',
                        hash: 'thumbnail_mobile_head_page_product_28bfe4a41c',
                        mime: 'image/jpeg',
                        name: 'thumbnail_mobile-head-page-product.jpg',
                        path: null,
                        size: 3.08,
                        width: 95,
                        height: 156,
                     },
                  },
                  hash: 'mobile_head_page_product_28bfe4a41c',
                  ext: '.jpg',
                  mime: 'image/jpeg',
                  size: 237.88,
                  url: '/uploads/mobile_head_page_product_28bfe4a41c.jpg',
                  previewUrl: null,
                  provider: 'local',
                  provider_metadata: null,
                  createdAt: '2023-12-20T02:26:14.850Z',
                  updatedAt: '2024-09-09T04:20:45.695Z',
               },
            },
         },
         thumbnail_video: {
            data: null,
         },
         mobile_thumbnail_video: {
            data: null,
         },
         localizations: {
            data: [
               {
                  id: 389,
                  attributes: {
                     info: 'Halaman Produk',
                     title: 'Jaringan Fiber Optik',
                     description:
                        '<p>Maksimalkan produktivitas dengan koneksi Internet cepat dan andal yang disertai layanan terbaik.</p>',
                     createdAt: '2023-06-08T08:30:29.951Z',
                     updatedAt: '2023-12-20T02:28:10.145Z',
                     publishedAt: '2023-06-08T08:30:31.279Z',
                     locale: 'id',
                     link: '/coverage-area',
                     isLinkTargetBlank: false,
                     linkName: 'Cek Jangkauan',
                     isVideo: false,
                  },
               },
            ],
         },
      },
   },
   {
      id: 385,
      attributes: {
         info: 'Page Product - Xtreme Lite',
         title: 'Unlimited Home Internet',
         description:
            '<p>Maximize your productivity with our lightning-fast Internet and enjoy the best-in-class service.</p>',
         createdAt: '2023-05-10T06:55:06.407Z',
         updatedAt: '2024-09-09T07:14:09.707Z',
         publishedAt: '2023-05-10T06:55:07.452Z',
         locale: 'en',
         link: '/coverage-area',
         isLinkTargetBlank: false,
         linkName: 'Check Coverage',
         isVideo: false,
         thumbnail: {
            data: {
               id: 3423,
               attributes: {
                  name: 'head-page-product-lite.jpg',
                  alternativeText: 'globalxtreme-head-page-product-lite',
                  caption: null,
                  width: 2667,
                  height: 1500,
                  formats: {
                     large: {
                        ext: '.jpg',
                        url: '/uploads/large_head_page_product_lite_b206dcd920.jpg',
                        hash: 'large_head_page_product_lite_b206dcd920',
                        mime: 'image/jpeg',
                        name: 'large_head-page-product-lite.jpg',
                        path: null,
                        size: 64.72,
                        width: 1000,
                        height: 562,
                     },
                     small: {
                        ext: '.jpg',
                        url: '/uploads/small_head_page_product_lite_b206dcd920.jpg',
                        hash: 'small_head_page_product_lite_b206dcd920',
                        mime: 'image/jpeg',
                        name: 'small_head-page-product-lite.jpg',
                        path: null,
                        size: 18.16,
                        width: 500,
                        height: 282,
                     },
                     medium: {
                        ext: '.jpg',
                        url: '/uploads/medium_head_page_product_lite_b206dcd920.jpg',
                        hash: 'medium_head_page_product_lite_b206dcd920',
                        mime: 'image/jpeg',
                        name: 'medium_head-page-product-lite.jpg',
                        path: null,
                        size: 37.61,
                        width: 750,
                        height: 422,
                     },
                     thumbnail: {
                        ext: '.jpg',
                        url: '/uploads/thumbnail_head_page_product_lite_b206dcd920.jpg',
                        hash: 'thumbnail_head_page_product_lite_b206dcd920',
                        mime: 'image/jpeg',
                        name: 'thumbnail_head-page-product-lite.jpg',
                        path: null,
                        size: 5.72,
                        width: 245,
                        height: 138,
                     },
                  },
                  hash: 'head_page_product_lite_b206dcd920',
                  ext: '.jpg',
                  mime: 'image/jpeg',
                  size: 221.08,
                  url: '/uploads/head_page_product_lite_b206dcd920.jpg',
                  previewUrl: null,
                  provider: 'local',
                  provider_metadata: null,
                  createdAt: '2023-12-20T02:31:55.018Z',
                  updatedAt: '2024-09-09T07:13:54.564Z',
               },
            },
         },
         mobile_thumbnail: {
            data: {
               id: 3424,
               attributes: {
                  name: 'mobile-head-page-product-lite.jpg',
                  alternativeText: 'globalxtreme-mobile-head-page-product-lite',
                  caption: null,
                  width: 1200,
                  height: 2134,
                  formats: {
                     large: {
                        ext: '.jpg',
                        url: '/uploads/large_mobile_head_page_product_lite_6ed758f9e2.jpg',
                        hash: 'large_mobile_head_page_product_lite_6ed758f9e2',
                        mime: 'image/jpeg',
                        name: 'large_mobile-head-page-product-lite.jpg',
                        path: null,
                        size: 55.43,
                        width: 562,
                        height: 1000,
                     },
                     small: {
                        ext: '.jpg',
                        url: '/uploads/small_mobile_head_page_product_lite_6ed758f9e2.jpg',
                        hash: 'small_mobile_head_page_product_lite_6ed758f9e2',
                        mime: 'image/jpeg',
                        name: 'small_mobile-head-page-product-lite.jpg',
                        path: null,
                        size: 15.39,
                        width: 281,
                        height: 500,
                     },
                     medium: {
                        ext: '.jpg',
                        url: '/uploads/medium_mobile_head_page_product_lite_6ed758f9e2.jpg',
                        hash: 'medium_mobile_head_page_product_lite_6ed758f9e2',
                        mime: 'image/jpeg',
                        name: 'medium_mobile-head-page-product-lite.jpg',
                        path: null,
                        size: 32.11,
                        width: 422,
                        height: 750,
                     },
                     thumbnail: {
                        ext: '.jpg',
                        url: '/uploads/thumbnail_mobile_head_page_product_lite_6ed758f9e2.jpg',
                        hash: 'thumbnail_mobile_head_page_product_lite_6ed758f9e2',
                        mime: 'image/jpeg',
                        name: 'thumbnail_mobile-head-page-product-lite.jpg',
                        path: null,
                        size: 2.53,
                        width: 88,
                        height: 156,
                     },
                  },
                  hash: 'mobile_head_page_product_lite_6ed758f9e2',
                  ext: '.jpg',
                  mime: 'image/jpeg',
                  size: 229.35,
                  url: '/uploads/mobile_head_page_product_lite_6ed758f9e2.jpg',
                  previewUrl: null,
                  provider: 'local',
                  provider_metadata: null,
                  createdAt: '2023-12-20T02:34:34.664Z',
                  updatedAt: '2024-09-09T07:14:08.423Z',
               },
            },
         },
         thumbnail_video: {
            data: null,
         },
         mobile_thumbnail_video: {
            data: null,
         },
         localizations: {
            data: [
               {
                  id: 398,
                  attributes: {
                     info: 'Halaman Produk - Xtreme Lite',
                     title: 'Internet Rumah Unlimited',
                     description:
                        '<p>Tingkatkan produktivitas Anda dengan Internet super cepat kami dan nikmati layanan terbaik di kelasnya.</p>',
                     createdAt: '2023-09-15T04:13:48.227Z',
                     updatedAt: '2024-08-08T03:08:47.306Z',
                     publishedAt: '2023-09-15T04:13:49.834Z',
                     locale: 'id',
                     link: '/coverage-area',
                     isLinkTargetBlank: false,
                     linkName: 'Cek Jangkauan',
                     isVideo: false,
                  },
               },
            ],
         },
      },
   },
   {
      id: 386,
      attributes: {
         info: 'Page Product - Signature',
         title: 'Business Internet',
         description:
            '<p>Explore high-speed Internet with our best packages. Enjoy quality connections and an exclusive online experience.</p>',
         createdAt: '2023-05-12T07:34:51.446Z',
         updatedAt: '2024-09-09T07:10:12.355Z',
         publishedAt: '2023-05-12T07:34:52.591Z',
         locale: 'en',
         link: '/coverage-area',
         isLinkTargetBlank: false,
         linkName: 'Check Coverage',
         isVideo: false,
         thumbnail: {
            data: {
               id: 3426,
               attributes: {
                  name: 'head-page-product-signature.jpg',
                  alternativeText: 'globalxtreme-head-page-product-signature',
                  caption: null,
                  width: 4000,
                  height: 2251,
                  formats: {
                     large: {
                        ext: '.jpg',
                        url: '/uploads/large_head_page_product_signature_67d3ef0007.jpg',
                        hash: 'large_head_page_product_signature_67d3ef0007',
                        mime: 'image/jpeg',
                        name: 'large_head-page-product-signature.jpg',
                        path: null,
                        size: 50.6,
                        width: 1000,
                        height: 563,
                     },
                     small: {
                        ext: '.jpg',
                        url: '/uploads/small_head_page_product_signature_67d3ef0007.jpg',
                        hash: 'small_head_page_product_signature_67d3ef0007',
                        mime: 'image/jpeg',
                        name: 'small_head-page-product-signature.jpg',
                        path: null,
                        size: 18,
                        width: 500,
                        height: 281,
                     },
                     medium: {
                        ext: '.jpg',
                        url: '/uploads/medium_head_page_product_signature_67d3ef0007.jpg',
                        hash: 'medium_head_page_product_signature_67d3ef0007',
                        mime: 'image/jpeg',
                        name: 'medium_head-page-product-signature.jpg',
                        path: null,
                        size: 32.06,
                        width: 750,
                        height: 422,
                     },
                     thumbnail: {
                        ext: '.jpg',
                        url: '/uploads/thumbnail_head_page_product_signature_67d3ef0007.jpg',
                        hash: 'thumbnail_head_page_product_signature_67d3ef0007',
                        mime: 'image/jpeg',
                        name: 'thumbnail_head-page-product-signature.jpg',
                        path: null,
                        size: 6.33,
                        width: 245,
                        height: 138,
                     },
                  },
                  hash: 'head_page_product_signature_67d3ef0007',
                  ext: '.jpg',
                  mime: 'image/jpeg',
                  size: 286.64,
                  url: '/uploads/head_page_product_signature_67d3ef0007.jpg',
                  previewUrl: null,
                  provider: 'local',
                  provider_metadata: null,
                  createdAt: '2023-12-20T02:50:15.840Z',
                  updatedAt: '2024-09-09T07:10:11.491Z',
               },
            },
         },
         mobile_thumbnail: {
            data: {
               id: 3381,
               attributes: {
                  name: 'mobile-head-page-product-signature.jpg',
                  alternativeText:
                     'globalxtreme-mobile-head-page-product-signature',
                  caption: null,
                  width: 3376,
                  height: 6001,
                  formats: {
                     large: {
                        ext: '.jpg',
                        url: '/uploads/large_mobile_head_page_product_signature_337a0c9ccd.jpg',
                        hash: 'large_mobile_head_page_product_signature_337a0c9ccd',
                        mime: 'image/jpeg',
                        name: 'large_mobile-head-page-product-signature.jpg',
                        path: null,
                        size: 46.89,
                        width: 563,
                        height: 1000,
                     },
                     small: {
                        ext: '.jpg',
                        url: '/uploads/small_mobile_head_page_product_signature_337a0c9ccd.jpg',
                        hash: 'small_mobile_head_page_product_signature_337a0c9ccd',
                        mime: 'image/jpeg',
                        name: 'small_mobile-head-page-product-signature.jpg',
                        path: null,
                        size: 16.3,
                        width: 281,
                        height: 500,
                     },
                     medium: {
                        ext: '.jpg',
                        url: '/uploads/medium_mobile_head_page_product_signature_337a0c9ccd.jpg',
                        hash: 'medium_mobile_head_page_product_signature_337a0c9ccd',
                        mime: 'image/jpeg',
                        name: 'medium_mobile-head-page-product-signature.jpg',
                        path: null,
                        size: 30.07,
                        width: 422,
                        height: 750,
                     },
                     thumbnail: {
                        ext: '.jpg',
                        url: '/uploads/thumbnail_mobile_head_page_product_signature_337a0c9ccd.jpg',
                        hash: 'thumbnail_mobile_head_page_product_signature_337a0c9ccd',
                        mime: 'image/jpeg',
                        name: 'thumbnail_mobile-head-page-product-signature.jpg',
                        path: null,
                        size: 2.99,
                        width: 88,
                        height: 156,
                     },
                  },
                  hash: 'mobile_head_page_product_signature_337a0c9ccd',
                  ext: '.jpg',
                  mime: 'image/jpeg',
                  size: 301.81,
                  url: '/uploads/mobile_head_page_product_signature_337a0c9ccd.jpg',
                  previewUrl: null,
                  provider: 'local',
                  provider_metadata: null,
                  createdAt: '2023-12-16T02:44:02.431Z',
                  updatedAt: '2024-09-09T07:09:35.061Z',
               },
            },
         },
         thumbnail_video: {
            data: null,
         },
         mobile_thumbnail_video: {
            data: null,
         },
         localizations: {
            data: [
               {
                  id: 401,
                  attributes: {
                     info: 'Halaman Produk - Signature',
                     title: 'Internet Bisnis',
                     description:
                        '<p>Jelajahi Internet berkecepatan tinggi dengan paket terbaik dari kami. Nikmati koneksi berkualitas dan pengalaman online yang eksklusif.</p>',
                     createdAt: '2023-12-13T09:49:33.859Z',
                     updatedAt: '2024-08-08T03:12:40.581Z',
                     publishedAt: '2023-12-13T09:49:35.010Z',
                     locale: 'id',
                     link: '/coverage-area',
                     isLinkTargetBlank: false,
                     linkName: 'Cek Jangkauan',
                     isVideo: false,
                  },
               },
            ],
         },
      },
   },
]
