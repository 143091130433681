let inDevEnvironment = false
if (process && process.env.NODE_ENV === 'development') {
   inDevEnvironment = true
}
export { inDevEnvironment }

export const getDomainFromENV = (extraUrl = '') => {
   let domain = ''

   if (inDevEnvironment) {
      domain = 'http://localhost:3000/' + extraUrl
   } else {
      domain = 'https://globalxtreme.net/' + extraUrl
   }

   return domain
}
