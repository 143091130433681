export const formatIndexToOrder = (index) => {
   const number = index + 1
   return number > 9 ? number : '0' + number
}

export const formatListWithNumberOrder = (data = {}, index) => {
   return { ...data, numberOrder: formatIndexToOrder(index) }
}

export const formatToNumber = (priceString) => {
   const priceNumber = parseFloat(
      priceString
         .replace(/[^\d.-]/g, '')
         .replace(/\./g, '')
         .replace(',', '.')
   )

   return priceNumber
}
