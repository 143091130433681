import _ from 'lodash'
import rehypeRaw from 'rehype-raw'
import ReactMarkdown from 'react-markdown'
import { useState } from 'react'
const SectionFrequently = ({ content = {} }) => {
   const [idQuestion, setIdQuestion] = useState('')

   return (
      <section
         className="sc-faq-only section-radius-top section-padding mt-n-2rem mobile-mt-n-0"
         data-scroll-section>
         <div className="container">
            <div className="row justify-content-md-center">
               <div className="col-md-8 col-12 text-center">
                  <p
                     data-scroll
                     data-scroll-speed="1"
                     className="text-dark-100 opacity-50">
                     {content.sub_title}
                  </p>
                  <h2
                     className="text-capitalize mb-5 fw-600"
                     data-scroll
                     data-scroll-speed="1.2">
                     {content.title}
                  </h2>
               </div>
            </div>

            <div className="row justify-content-md-center">
               <div className="col-lg-8 col-12">
                  <div className="accordion" id="accordionExample">
                     {!_.isEmpty(content.points)
                        ? content.points.map((question, index) => {
                             const idHead = 'headingOne' + index
                             const idBody = 'collapseOne' + index
                             const isActive = idQuestion === index

                             return (
                                <div
                                   key={index}
                                   data-scroll
                                   data-scroll-speed="1.5"
                                   className={
                                      'card card-faq border-0 accordion-arrow-180 ' +
                                      (isActive ? 'active' : '')
                                   }
                                   id={idHead}>
                                   <div
                                      className="card-header border-0"
                                      onClick={() =>
                                         setIdQuestion(!isActive ? index : '')
                                      }
                                      role="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target={'#' + idBody}
                                      aria-expanded="true"
                                      aria-controls={idBody}
                                      aria-labelledby={idBody}>
                                      <div className="d-flex justify-content-between align-items-center">
                                         <h3 className="mb-0 h5 text-decoration-none pe-3">
                                            {question.title}
                                         </h3>
                                         <img
                                            src="/images/icon/icon-arrow-down.svg"
                                            alt="GlobalXtreme FAQ"
                                            className="arrow-icon"
                                            width="auto"
                                            height="auto"
                                            loading="lazy"
                                         />
                                      </div>
                                   </div>

                                   <div
                                      id={idBody}
                                      className="collapse opacity-75"
                                      // aria-labelledby={idHead}
                                      data-bs-parent="#accordionExample">
                                      <div className="card-body normal-size opacity-75">
                                         <ReactMarkdown
                                            rehypePlugins={[rehypeRaw]}>
                                            {question.description}
                                         </ReactMarkdown>
                                      </div>
                                   </div>
                                </div>
                             )
                          })
                        : null}
                  </div>
               </div>
            </div>
         </div>
      </section>
   )
}

export default SectionFrequently
