import Marquee from 'react-fast-marquee'
import rehypeRaw from 'rehype-raw'
import ReactMarkdown from 'react-markdown'
import { _convertCMSFile } from '@/helper/manageParameter'

const SectionTitle = ({ content = {} }) => {
   return (
      <section
         className="marquee-text-unrivaled hv-min-100 section bg-primary-theme"
         data-scroll-section
         data-scroll-speed="2"
         data-scroll-direction="horizontal"
         data-scroll-repeat>
         <img
            src={_convertCMSFile(content?.thumbnail?.url)}
            alt="GlobalXtreme Internet Bali, Balikpapan, Malang, Samarinda, Denpasar"
            className="bg-icon-pills"
            data-scroll
            data-scroll-speed="3"
            width="auto"
            height="auto"
            loading="lazy"
         />

         <div
            data-scroll
            data-scroll-direction="horizontal"
            data-scroll-position="center"
            data-scroll-speed="3">
            <Marquee gradient={false} speed={200}>
               <div className="text-unrivaled-slider">
                  <h2 className="text-info mb-0">{content.title}</h2>
               </div>
            </Marquee>
         </div>

         <div className="container" data-scroll data-scroll-speed="1">
            <div className="row">
               <div className="offset-xl-7 col-12 col-xl-5 wp-text-tint-100">
                  <ReactMarkdown
                     rehypePlugins={[rehypeRaw]}
                     className="wp-text-h4 text-description-wrapper">
                     {content.description}
                  </ReactMarkdown>
               </div>
            </div>
         </div>
      </section>
   )
}

export default SectionTitle
