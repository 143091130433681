import { ArrowUpIcon } from '@heroicons/react/24/outline'
import _ from 'lodash'
import rehypeRaw from 'rehype-raw'
import ReactMarkdown from 'react-markdown'
import { _checkImgCoverBlog, _convertCMSFile } from '@/helper/manageParameter'
import Marquee from 'react-fast-marquee'
import { BtnLinkPrimaryArrow } from '@/component/general/Button'
import { useRouter } from 'next/router'
import { localeLinkGeneral } from '@/content/translate/locale-link'
import Link from 'next/link'
import maxAlt from '@/helper/maxAlt'

const SectionBannerAndPartner = ({
   main = {},
   features = [],
   partnerships = [],
   blogs = [],
}) => {
   const { locale } = useRouter()
   const myAppKey = 'my-app-card-' + locale
   const myWeBackKey = 'we-back-card-' + locale
   const myPromoKey = 'my-promo-card-' + locale

   const myApp = !_.isEmpty(features)
      ? features.filter((e) => e.unic === myAppKey)
      : []

   const myWeBack = !_.isEmpty(features)
      ? features.filter((e) => e.unic === myWeBackKey)
      : []

   const myPromo = !_.isEmpty(features)
      ? features.filter((e) => e.unic === myPromoKey)
      : []

   // const [idQuestion, setIdQuestion] = useState('')

   return (
      <section
         className="bg-black section-radius-top hv-min-100P"
         data-scroll-section>
         {/*<div className="gx-app-promo">*/}
         <div className="py-5 container gx-app-promo">
            <div className="row">
               {!_.isEmpty(myApp) ? (
                  <div
                     className="col-12 col-xl-6 mb-4 mb-xl-0"
                     data-scroll
                     data-scroll-speed="1">
                     <div className="card card-mobile-app text-white justify-content-between">
                        <figure className="img-wrapper">
                           <img
                              src={_convertCMSFile(myApp[0].background.url)}
                              alt="GlobalXtrem Promotion and Gift"
                              className="mobile-app-bg"
                              width="auto"
                              height="auto"
                              loading="lazy"
                           />
                        </figure>
                        <div className="img-linear-background"></div>
                        <div className="content-wrapper mb-4 mb-xl-0">
                           <div className="text-primary-yellow mb-5">
                              <ReactMarkdown
                                 rehypePlugins={[rehypeRaw]}
                                 className="wp-text-tint-300 my-gx-app-text">
                                 {myApp[0].title}
                              </ReactMarkdown>
                           </div>

                           <div className="text-white">
                              <ReactMarkdown
                                 rehypePlugins={[rehypeRaw]}
                                 className="opacity-75">
                                 {myApp[0].description}
                              </ReactMarkdown>
                           </div>
                        </div>
                        <div className="d-flex align-items-center mobile-app-wrapper">
                           {myApp[0].links.map((app, idx) => (
                              <a href={app.url} target="_blank" key={idx}>
                                 <img
                                    src={_convertCMSFile(app.icon.url)}
                                    alt="GlobalXtreme app for payment"
                                    width="auto"
                                    height="auto"
                                    loading="lazy"
                                 />
                              </a>
                           ))}
                        </div>
                     </div>
                  </div>
               ) : null}

               <div className="col-12 col-xl-6">
                  <div className="row">
                     {!_.isEmpty(myWeBack) ? (
                        <div
                           className="col-12 col-md-6 mb-4 mb-sm-0"
                           data-scroll
                           data-scroll-speed="1.2">
                           <div className="card card-promo text-white">
                              <div className="content-wrapper">
                                 <div className="text-primary-yellow mb-0">
                                    <ReactMarkdown
                                       rehypePlugins={[rehypeRaw]}
                                       className="wp-text-tint-300 gx-promo-text">
                                       {myWeBack[0].title}
                                    </ReactMarkdown>
                                 </div>

                                 <div className="">
                                    <ReactMarkdown
                                       rehypePlugins={[rehypeRaw]}
                                       className="opacity-75">
                                       {myWeBack[0].description}
                                    </ReactMarkdown>
                                 </div>
                              </div>
                           </div>
                        </div>
                     ) : null}

                     {!_.isEmpty(myPromo) ? (
                        <div
                           className="col-12 col-md-6"
                           data-scroll
                           data-scroll-speed="1.4">
                           <Link
                              href={
                                 localeLinkGeneral.homePromotion[locale].link
                              }>
                              <div className="card card-promo bg-primary-theme">
                                 <figure className="img-wrapper">
                                    <img
                                       src={_convertCMSFile(
                                          myPromo[0].background.url
                                       )}
                                       alt="GlobalXtreme Promotion, GlobalXtrem Promo"
                                       className="mobile-app-bg"
                                       width="auto"
                                       height="auto"
                                       loading="lazy"
                                    />
                                 </figure>
                                 <div className="content-wrapper">
                                    <div className="text-black text-promo">
                                       <ReactMarkdown
                                          rehypePlugins={[rehypeRaw]}
                                          className="gx-promo-text">
                                          {myPromo[0].title}
                                       </ReactMarkdown>
                                    </div>

                                    <ReactMarkdown
                                       rehypePlugins={[rehypeRaw]}
                                       className="opacity-75">
                                       {myPromo[0].description}
                                    </ReactMarkdown>
                                 </div>
                                 <div className="align-self-end mt-4 mt-md-3">
                                    <ArrowUpIcon
                                       height={52}
                                       width={52}
                                       strokeWidth={1.25}
                                       className="text-black rotate-45"
                                    />
                                 </div>
                              </div>
                           </Link>
                        </div>
                     ) : null}
                  </div>
               </div>
            </div>

            {/*<div className="row py-5">*/}
            {/*    <div*/}
            {/*        className="col-md-12 text-center py-5"*/}
            {/*        data-scroll*/}
            {/*        data-scroll-speed="1.2">*/}
            {/*        <BtnLinkPrimaryArrow*/}
            {/*            name={localeLinkGeneral.learnMore[locale].name}*/}
            {/*            extraClass="fw-semibold"*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*</div>*/}
         </div>
         {/*</div>*/}

         <div className="my-partner">
            <div className="container">
               <div className="row py-5">
                  <div className="col-11 offset-1 col-md-12 col-xl-5 offset-xl-6">
                     {main.description ? (
                        <div data-scroll data-scroll-speed="1">
                           <ReactMarkdown
                              rehypePlugins={[rehypeRaw]}
                              className="wp-text-tint-300 partner-description-wrapper">
                              {main.description}
                           </ReactMarkdown>
                        </div>
                     ) : null}

                     {main.title ? (
                        <p
                           className="text-white mt-5 partner-title-wrapper"
                           data-scroll
                           data-scroll-speed="1">
                           {main.title}
                        </p>
                     ) : null}
                  </div>
               </div>
            </div>

            <div data-scroll data-scroll-speed="1">
               <Marquee
                  gradient={false}
                  className="partner-slideshow mb-3"
                  speed={60}
                  pauseOnHover={true}
                  autofill
                  loop={0}>
                  {partnerships.slice(0, partnerships.length / 2).map((vm, index) => (
                     <div className="card card-partner" key={index}>
                        <img
                           src={_convertCMSFile(vm.logo.url || '')}
                           alt={vm.alt || 'globalxtreme partnership'}
                           className="img-partner"
                           width="auto"
                           height="auto"
                           loading="lazy"
                        />
                     </div>
                  ))}
               </Marquee>
               <Marquee
                  gradient={false}
                  className="partner-slideshow"
                  speed={60}
                  direction="right"
                  pauseOnHover={true}
                  loop={0}>
                  {partnerships.slice(partnerships.length / 2, partnerships.length).map((vm, index) => (
                     <div className="card card-partner" key={index}>
                        <img
                           src={_convertCMSFile(vm.logo.url || '')}
                           alt={vm.alt || 'globalxtreme partnership'}
                           className="img-partner"
                           width="auto"
                           height="auto"
                           loading="lazy"
                        />
                     </div>
                  ))}
               </Marquee>
            </div>
         </div>

         {blogs.length ? (
            <div className="faq-text-animation">
               <Marquee
                  gradient={false}
                  className="faq-text-slider mb-3"
                  speed={60}
                  pauseOnHover={true}
                  loop={0}>
                  {blogs &&
                     blogs
                        .slice(0, 2)
                        .map((blog, index) => (
                           <MarqueeContentBlog blog={blog} key={index} />
                        ))}
               </Marquee>

               {blogs && blogs.length > 3 ? (
                  <Marquee
                     gradient={false}
                     className="faq-text-slider mb-3"
                     speed={60}
                     pauseOnHover={true}
                     loop={0}
                     delay={3}>
                     {blogs &&
                        blogs
                           .slice(3, 5)
                           .map((blog, index) => (
                              <MarqueeContentBlog blog={blog} key={index} />
                           ))}
                  </Marquee>
               ) : null}

               {blogs && blogs.length > 6 ? (
                  <Marquee
                     gradient={false}
                     className="faq-text-slider mb-3"
                     speed={60}
                     pauseOnHover={true}
                     loop={0}
                     delay={5}>
                     {blogs &&
                        blogs
                           .slice(6, 8)
                           .map((blog, index) => (
                              <MarqueeContentBlog blog={blog} key={index} />
                           ))}
                  </Marquee>
               ) : null}
            </div>
         ) : null}
      </section>
   )
}

const MarqueeContentBlog = ({ blog = {} }) => (
   <Link
      href={'/info/blog/' + blog.slug}
      className="me-4 sc-home-wp-blog a-link-default">
      {_checkImgCoverBlog(blog.yoast_head_json) ? (
         <img
            src={_checkImgCoverBlog(blog.yoast_head_json)}
            alt={
               blog?.title?.rendered
                  ? maxAlt(blog.title.rendered)
                  : 'GlobalXtreme blog'
            }
            width="auto"
            height="auto"
            loading="lazy"
         />
      ) : null}

      <p
         className="mb-0 text-primary-yellow"
         dangerouslySetInnerHTML={{
            __html: blog?.title?.rendered || '',
         }}
      />
   </Link>
)

export default SectionBannerAndPartner
