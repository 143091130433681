import * as Icon from 'react-feather'

const AccordionFaq = ({
   isActive,
   setActive,
   children,
   title,
   faqNumber,
   id,
   idHeading,
   dataParent,
   ariaExpanded,
   extraClassBody = '',
   isLast = 0,
}) => {
   const dataTarget = `#${id}`

   return (
      <div
         className="row faq-list mb-0"
         data-scroll
         data-scroll-delay=".5"
         data-scroll-speed="1.5"
         data-scroll-repeat>
         <div className="col-auto col-lg-3 col-xl-4 text-end opacity-50 py-3">
            <sup>{faqNumber}</sup>
         </div>
         <div className="col col-lg-9 col-xl-8">
            <div className={!isLast ? 'faq-list-border' : ''} id={idHeading}>
               <div className="card border-0">
                  <div
                     className="card-header border-0 bg-transparent p-0"
                     aria-expanded={ariaExpanded}
                     onClick={() => setActive(isActive)}
                     data-bs-toggle="collapse"
                     data-bs-target={'#' + id}
                     aria-controls={id}
                     aria-labelledby={id}
                     role="button">
                     <div className="row align-items-center pb-3 pt-2">
                        <div className="col-10 col-lg-8 col-xl-auto">
                           <h3 className="h5 mb-0">{title}</h3>
                        </div>
                        <div className="col-2 col-lg-4 col-xl d-flex justify-content-end">
                           <div
                              className={
                                 'faq-list-icon use-arrow-active ' +
                                 (isActive ? 'active' : '')
                              }>
                              <Icon.ArrowDownRight
                                 strokeWidth={1}
                                 className="text-black"
                                 // data-toggle="collapse"
                                 // data-target={dataTarget}
                                 // aria-expanded={ariaExpanded}
                                 // aria-controls={id}
                                 // aria-labelledby={id}
                              />
                           </div>
                        </div>
                     </div>
                  </div>
                  <div
                     id={id}
                     // className="collapse"
                     // aria-labelledby={idHeading}
                     // data-parent={dataParent}
                     // id="flush-collapseOne"
                     className="accordion-collapse collapse"
                     // aria-labelledby="flush-headingOne"
                     data-bs-parent={dataParent}>
                     <div
                        className={
                           'card-body p-0 af-description ' + extraClassBody
                        }>
                        {children}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default AccordionFaq
