import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { motion, AnimatePresence, useAnimation } from 'framer-motion'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { BtnLinkPrimaryArrow } from '@/component/general/Button'
import { _convertCMSFile } from '@/helper/manageParameter'
import { localeDataGeneral } from '@/content/translate/locale-data'

const INITIAL_INDEX_COUNT = 0

// const images = [
//    'https://images.pexels.com/photos/4491451/pexels-photo-4491451.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
//    'https://images.pexels.com/photos/4347366/pexels-photo-4347366.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
//    'https://images.pexels.com/photos/3184405/pexels-photo-3184405.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
//    'https://images.pexels.com/photos/4260323/pexels-photo-4260323.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
// ]

const SectionTabStoryNew = ({ content = {}, tabs = [] }) => {
   const [currentIndex, setCurrentIndex] = useState(INITIAL_INDEX_COUNT)
   const [activeTab, setActiveTab] = useState(0)
   const controls = useAnimation()
   // const tabCount = tabs.length
   const router = useRouter()

   const _handleNextTabs = (index) => {
      setCurrentIndex(index)
      setActiveTab(index)
   }

   // const handleSwipe = (direction) => {
   //    if (direction === 'left') {
   //       setActiveTab((prevTab) => (prevTab < tabCount - 1 ? prevTab + 1 : prevTab));
   //    } else if (direction === 'right') {
   //       setActiveTab((prevTab) => (prevTab > 0 ? prevTab - 1 : prevTab));
   //    }
   // };

   const swipeVariants = {
      fadeOut: {
         // x: '-100%',
         opacity: 0,
         transition: {
            duration: 0.8,
            delay: 1,
            ease: 'easeInOut',
         },
      },
      fadeIn: {
         // x: '0%',
         opacity: 1,
         transition: {
            duration: 0.8,
            delay: 1,
            ease: 'linear',
         },
      },
      exit: {
         opacity: 0,
         transition: {
            duration: 0.3,
         },
      },
   }

   useEffect(() => {
      controls.start(swipeVariants.fadeIn)
   }, [])

   return (
      <section
         className="carousel-custom bg-dark-200 sc-home-tab-story min-vh-100 overflow-hidden"
         data-scroll-section>
         <div className="carousel-images-custom fade-slider">
            <AnimatePresence>
               <motion.img
                  key={currentIndex}
                  // src={images[currentIndex]}
                  src={_convertCMSFile(tabs[currentIndex].thumbnail.url)}
                  className="slider-item"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.8, delay: 0.3, ease: 'easeInOut' }}
                  exit={{ opacity: 0 }}
                  data-scroll
                  data-scroll-speed="-4"
               />
            </AnimatePresence>

            <div className="overflow-background"></div>
         </div>

         <div className="nav-tab-wrapper">
            <div className="container">
               <div className="row">
                  <div className="col-12 col-xl-10 offset-xl-1">
                     <ul
                        className="nav nav-tabs border-bottom-0 px-4 px-md-5 px-lg-0"
                        id="myTab"
                        role="tablist">
                        {tabs.map((vm, index) => (
                           <li
                              key={index}
                              className="nav-item"
                              role="presentation"
                              // data-scroll
                              // data-scroll-speed="1"
                           >
                              <button
                                 className={`nav-link border-0 bg-transparent text-light-200 text-nowrap 
                                    ${index === activeTab ? 'active' : ''}`}
                                 id={'professional-tab-' + index}
                                 type="button"
                                 role="tab"
                                 aria-controls={'professional'}
                                 aria-selected={index < 1 ? 'true' : 'false'}
                                 data-bs-toggle="tab"
                                 data-bs-target={'#professional' + index}
                                 onClick={() => _handleNextTabs(index)}>
                                 {vm.title}
                              </button>
                           </li>
                        ))}
                     </ul>

                     <div
                        className="tab-content mt-0 px-4 px-md-5 px-lg-0"
                        id="myTabContent">
                        {tabs.map((vm, index) => (
                           <motion.div
                              key={index}
                              className={`tab-pane ${
                                 index === activeTab ? 'active show' : ''
                              }`}
                              id={'professional' + index}
                              // id={index}
                              role="tabpanel"
                              aria-labelledby={'professional-tab'}
                              // aria-labelledby={index}
                              animate={
                                 index === activeTab ? 'fadeIn' : 'fadeOut'
                              }
                              initial="fadeIn"
                              exit="exit"
                              variants={swipeVariants}
                              transition={{ duration: 0.3 }}
                              onAnimationComplete={() =>
                                 controls.start(swipeVariants.fadeIn)
                              }>
                              <div className="row">
                                 <div
                                    className="col-12"
                                    // data-scroll
                                    // data-scroll-speed="1"
                                 >
                                    <div className="wp-tap-pane wp-text-h3 wp-text-tint-300">
                                       <ReactMarkdown
                                          rehypePlugins={[rehypeRaw]}
                                          className="wp-tap-pane wp-text-h3 wp-text-tint-300">
                                          {vm.description}
                                       </ReactMarkdown>
                                    </div>
                                 </div>
                              </div>

                              {vm.link && false ? (
                                 <div data-scroll data-scroll-speed="1.2">
                                    <BtnLinkPrimaryArrow
                                       name={
                                          localeDataGeneral.homeTabStory[
                                             router.locale
                                          ]
                                       }
                                       extraClass="fw-semibold"
                                    />
                                 </div>
                              ) : null}
                           </motion.div>
                        ))}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   )
}
export default SectionTabStoryNew
