import { _convertCMSFile } from '@/helper/manageParameter'
import * as Icon from 'react-feather'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { BtnLinkPrimaryArrow } from '@/component/general/Button'
import { localeLinkGeneral } from '@/content/translate/locale-link'
import { useRouter } from 'next/router'

const SectionTabStory = ({ content = {}, tabs = [] }) => {
   const router = useRouter()
   return (
      <section
         className="bg-primary-theme sc-home-tab-story min-vh-100 overflow-hidden"
         data-scroll-section>
         <img
            src={_convertCMSFile(content?.thumbnail?.url || '')}
            alt="globalxtreme strory data"
            className="nav-tab-banner"
            data-scroll
            data-scroll-speed="-4"
            width="auto"
            height="auto"
            loading="lazy"
         />
         <div className="nav-tab-wrapper">
            <div className="container">
               <div className="row">
                  <div className="col-12 col-xl-10 offset-xl-1">
                     <ul
                        className="nav nav-tabs border-bottom-0"
                        id="myTab"
                        role="tablist">
                        {tabs.map((vm, index) => {
                           return (
                              <li
                                 key={index}
                                 className="nav-item"
                                 role="presentation"
                                 data-scroll
                                 data-scroll-speed="1">
                                 <button
                                    className={
                                       'nav-link border-0 bg-transparent text-light-200 text-nowrap ' +
                                       (index < 1 ? 'active' : '')
                                    }
                                    id={'professional-tab-' + index}
                                    type="button"
                                    role="tab"
                                    aria-controls={'professional'}
                                    aria-selected={index < 1 ? 'true' : 'false'}
                                    data-bs-toggle="tab"
                                    data-bs-target={'#professional' + index}>
                                    {vm.title}
                                 </button>
                              </li>
                           )
                        })}
                     </ul>

                     <div className="tab-content" id="myTabContent">
                        {tabs.map((vm, index) => {
                           return (
                              <div
                                 className={
                                    'tab-pane ' + (index < 1 ? 'active' : '')
                                 }
                                 id={'professional' + index}
                                 // id={index}
                                 role="tabpanel"
                                 aria-labelledby={'professional-tab'}
                                 // aria-labelledby={index}
                                 key={index}>
                                 <div className="row">
                                    <div
                                       className="col-12 wp-text-tint-300"
                                       data-scroll
                                       data-scroll-speed="1">
                                       <ReactMarkdown
                                          rehypePlugins={[rehypeRaw]}>
                                          {vm.description}
                                       </ReactMarkdown>
                                    </div>
                                 </div>

                                 {vm.link && false ? (
                                    <div data-scroll data-scroll-speed="1.2">
                                       <BtnLinkPrimaryArrow
                                          name={
                                             localeLinkGeneral.homeTabStory[
                                                router.locale
                                             ].name
                                          }
                                          extraClass="fw-semibold"
                                       />
                                    </div>
                                 ) : null}
                              </div>
                           )
                        })}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   )
}

export default SectionTabStory
