import { useEffect, useState } from 'react'
import { SlidePageHomeMyProduct } from '@/component/general/SwiperProduct'
import { ArrowUpRightIcon } from '@heroicons/react/24/outline'
import _ from 'lodash'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination } from 'swiper'
import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'
import AccordionFaq from '@/component/accordion/AccordionFaq'
import { BtnLinkPrimaryArrow } from '@/component/general/Button'
import rehypeRaw from 'rehype-raw'
import ReactMarkdown from 'react-markdown'
import { useRouter } from 'next/router'
import { _convertCMSFile } from '@/helper/manageParameter'
import { localeDataGeneral } from '@/content/translate/locale-data'

SwiperCore.use([Pagination])

const SectionInfoPackage = ({
   main = {},
   contentPackages = [],
   contentFaq = {},
}) => {
   const router = useRouter()
   const [active, setActive] = useState(null)

   return (
      <section
         className="sc-home-info-package bg-white overflow-hidden mt-n-2rem section-radius-top"
         data-scroll-section
         // data-scroll-speed="2"
         // data-scroll-direction="horizontal"
         // data-scroll-repeat
      >
         {!_.isEmpty(main) && !_.isEmpty(main.thumbnail) ? (
            <img
               // src="/images/icon/illustration-globe-dark.svg"
               // src="/images/page-home/bg-gray-line-tecnologi.svg"
               src={_convertCMSFile(main.thumbnail.url)}
               alt="GlobalXtreme - Information Package"
               className="bg-image-abstract"
               width="auto"
               height="auto"
               loading="lazy"
            />
         ) : null}
         <div className="img-linear-background" />

         {!_.isEmpty(contentPackages) ? (
            <div
               className="wrap-package-info px-4 px-md-5 px-lg-0 section-padding"
               data-scroll
               data-scroll-delay=".5"
               // data-scroll-speed=".5"
               data-scroll-speed="1"
               data-scroll-repeat>
               <SlidePageHomeMyProduct
                  products={contentPackages}
                  isTitleImage
               />
            </div>
         ) : null}

         <div
            className="wrap-accordion-faq-arrow-diagonal faq accordion"
            id="accordionFaq">
            {!_.isEmpty(contentFaq) &&
            !_.isEmpty(contentFaq.accordion_faq) &&
            !_.isEmpty(contentFaq.accordion_faq.points) ? (
               <>
                  <div className="px-md-5 py-5 container-fluid">
                     <div
                        className="row mb-5"
                        data-scroll
                        data-scroll-delay=".5"
                        data-scroll-speed="1"
                        data-scroll-repeat>
                        <div className="col-11 offset-1 col-md-8 offset-md-4 text-start">
                           <p className="text-dark-100 opacity-50 ps-1 ps-md-0 mb-0">
                              {contentFaq?.accordion_faq?.title || ''}
                           </p>
                        </div>
                     </div>

                     {contentFaq.accordion_faq.points.map((vm, index) => {
                        const number = index + 1

                        return (
                           <AccordionFaq
                              key={index}
                              setActive={setActive}
                              isActive={active === number ? null : number}
                              title={vm.title}
                              faqNumber={number < 10 ? '0' + number : number}
                              id={'faq-' + index}
                              idHeading={'faqHeading-' + index}
                              dataParent="#accordionFaq"
                              ariaExpanded={index < 1 ? 'true' : 'false'}
                              extraClassBody="opacity-50-percent wp-faq-card-body"
                              isLast={
                                 contentFaq.accordion_faq.points.length ==
                                 number
                                    ? 1
                                    : 0
                              }>
                              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                                 {vm.description}
                              </ReactMarkdown>
                           </AccordionFaq>
                        )
                     })}

                     <div className="row mt-5">
                        <div
                           className="col-12 col-lg-8 offset-lg-4 d-flex justify-content-center justify-content-md-start"
                           data-scroll
                           data-scroll-delay=".5"
                           data-scroll-speed="2"
                           data-scroll-repeat>
                           <BtnLinkPrimaryArrow
                              name={localeDataGeneral.exploreFaq[router.locale]}
                              extraClass="fw-semibold"
                              href="/info/customer-support"
                           />
                        </div>
                     </div>
                  </div>
               </>
            ) : null}

            <div className="container">
               <div className="row space-description-to-btn">
                  <div
                     className="col-12 col-xl-12"
                     data-scroll
                     data-scroll-delay=".5"
                     data-scroll-speed="2.5"
                     data-scroll-repeat>
                     <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        className="wp-text-h1 wp-title-description wp-tint-300-span fw-medium">
                        {main.description}
                     </ReactMarkdown>
                  </div>
               </div>
            </div>
         </div>
      </section>
   )
}

export default SectionInfoPackage
