import Link from 'next/link'
import * as Icon from 'react-feather'

export const BtnPrimaryArrow = ({ name, type = 'button', extraClass = '' }) => {
   return (
      <button className="btn btn-primary-theme" type={type}>
         <span className="me-4">{name}</span>
         <Icon.ArrowUpRight
            size={24}
            strokeWidth={1.5}
            className="text-black"
         />
      </button>
   )
}

export const BtnLinkPrimaryArrow = ({
   name,
   href = '#',
   isOutline = false,
   extraClass = '',
   ...props
}) => (
   <Link
      className={
         'btn btn-gx-theme ' +
         (isOutline ? 'btn-outline-primary-theme ' : 'btn-primary-theme ') +
         extraClass
      }
      href={href}
      {...props}>
      <span className="me-auto">{name}</span>
      <Icon.ArrowUpRight size={24} strokeWidth={1.5} className="text-black" />
   </Link>
)

export const BtnLinkPrimaryBlackArrow = ({
   name,
   href = '#',
   isOutline = false,
   extraClass = '',
   ...props
}) => (
   <Link
      className={
         'btn btn-gx-theme ' +
         (isOutline ? 'btn-outline-dark-100 ' : 'btn-dark-100 ') +
         extraClass
      }
      href={href}
      {...props}>
      <span className="me-auto">{name}</span>
      <Icon.ArrowUpRight size={24} strokeWidth={1.5} />
   </Link>
)

export const BtnControlHeroAudio = ({
   isMuted = false,
   extraClass = '',
   _handlePlayAudio = () => {},
}) => {
   return (
      <div
         className={
            'wp-hero-toggle-audio bg-transparent rounded-circle cursor-pointer ' +
            extraClass
         }
         onClick={() => _handlePlayAudio()}>
         {isMuted ? (
            <Icon.VolumeX className="text-white" width="100%" height="100%" />
         ) : (
            <Icon.Volume2 className="text-white" width="100%" height="100%" />
         )}
      </div>
   )
}
