const maxAlt = (passAlt = '') => {
   const maxAltLength = 15

   const configMaxAlt =
      passAlt.length > maxAltLength
         ? passAlt.substring(0, maxAltLength)
         : passAlt

   return configMaxAlt
}

export default maxAlt
