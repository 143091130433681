import { _get } from '@/content/cms/_config'
import axios from 'axios'
// const baseURL = process.env.cms_wp
const baseURL = 'https://blog.globalxtreme.net/wp-json/wp/v2'

const configGETWP = async (url = '') =>
   await axios
      .get('https://blog.globalxtreme.net/wp-json/wp/v2/' + url)
      .then((response) => {
         return response?.data || {}
      })
      .catch((err) => {
         console.log('err: ', err)
      })

export const contentBlogs = (search = '') => configGETWP('posts' + search)

export const contentPromotions = (search = '') =>
   configGETWP('promotion' + search)

// export const contentBlogs = (search = '') => _get(baseURL + '/posts' + search)
//
// export const contentPromotions = (search = '') =>
//    _get(baseURL + '/promotion' + search)
